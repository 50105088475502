<template>
  <BasePage>
    <div className="text_wrap">
      <p>
        Your privacy is important to us. It is ZY Adstech' policy to respect
        your privacy regarding any information we may collect from you across
        our website, and other sites we own and operate.
      </p>
      <p>Last Update: May 6, 2022</p>
      <br />

      <p>1. Information we collect Log data</p>

      <br />
      <p>
        When you visit our website, our servers may automatically log the
        standard data provided by your web browser. It may include your
        computer’s Internet Protocol (IP) address, your browser type and
        version, the pages you visit, the time and date of your visit, the time
        spent on each page, and other details.
      </p>

      <br />
      <p>Device data</p>
      <p>
        We may also collect data about the device you’re using to access our
        website. This data may include the device type, operating system, unique
        device identifiers, device settings, and geo-location data. What we
        collect can depend on the individual settings of your device and
        software. We recommend checking the policies of your device manufacturer
        or software provider to learn what information they make available to
        us.
      </p>

      <br />
      <p>Personal information</p>
      <p>We may ask for personal information, such as your:</p>

      <br />
      <p>Email</p>
      <p>
        In order to match you with the right surveys, we may collect additional
        information from you, including demographic information such as postal
        code, gender, marital status, education, employment related information,
        and non-personally identifiable information on household members,
        household income, age, etc.
      </p>

      <br />
      <p>2. Legal bases for processing</p>
      <p>
        We will process your personal information lawfully, fairly and in a
        transparent manner. We collect and process information about you only
        where we have legal bases for doing so.
      </p>
      <br />
      <p>
        These legal bases depend on the services you use and how you use them,
        meaning we collect and use your information only where:
      </p>
      <br />
      <p>
        it’s necessary for the performance of a contract to which you are a
        party or to take steps at your request before entering into such a
        contract (for example, when we provide a service you request from us);
      </p>
      <br />
      <p>
        it satisfies a legitimate interest (which is not overridden by your data
        protection interests), such as for research and development, to market
        and promote our services, and to protect our legal rights and interests;
      </p>
      <br />
      <p>
        you give us consent to do so for a specific purpose (for example, you
        might consent to us sending you our newsletter); or
      </p>
      <p>we need to process your data to comply with a legal obligation.</p>
      <p>
        Where you consent to our use of information about you for a specific
        purpose, you have the right to change your mind at any time (but this
        will not affect any processing that has already taken place).
      </p>
      <br />
      <p>
        We don’t keep personal information for longer than is necessary. While
        we retain this information, we will protect it within commercially
        acceptable means to prevent loss and theft, as well as unauthorized
        access, disclosure, copying, use or modification. That said, we advise
        that no method of electronic transmission or storage is 100% secure and
        cannot guarantee absolute data security. If necessary, we may retain
        your personal information for our compliance with a legal obligation or
        in order to protect your vital interests or the vital interests of
        another natural person.
      </p>
      <br />
      <p>3. Legal bases for processing</p>
      <br />
      <p>
        We may collect, hold, use and disclose information for the following
        purposes and personal information will not be further processed in a
        manner that is incompatible with these purposes:
      </p>
      <br />
      <p>
        to match you with surveys based on your demographic information and the
        surveys targeting criteria
      </p>
      <br />
      <p>
        to enable you to customize or personalize your experience of our
        website;
      </p>
      <br />
      <p>
        to enable you to access and use our website, associated applications and
        associated social media platforms;
      </p>
      <br />
      <p>to contact and communicate with you;</p>
      <br />
      <p>for internal record keeping and administrative purposes;</p>
      <br />
      <p>
        for analytics, market research and business development, including to
        operate and improve our website, associated applications and associated
        social media platforms;
      </p>
      <br />
      <p>
        for advertising and marketing, including to send you promotional
        information about our products and services and information about third
        parties that we consider may be of interest to you;
      </p>
      <br />
      <p>
        to comply with our legal obligations and resolve any disputes that we
        may have;
      </p>
      <br />
      <p>4. Disclosure of personal information to third parties</p>
      <br />
      <p>We may disclose personal information to:</p>
      <br />
      <p>
        third party service providers for the purpose of enabling them to
        provide their services, including (without limitation) IT service
        providers, data storage, hosting and server providers, ad networks,
        analytics, error loggers, debt collectors, maintenance or
        problem-solving providers, marketing or advertising providers,
        professional advisors and payment systems operators;
        <br />
      </p>
      <br />
      <p>our employees, contractors and/or related entities;</p>
      <br />
      <p>sponsors or promoters of any competition we run; and</p>
      <p>
        third parties, including agents or sub-contractors, who assist us in
        providing information, products, services or direct marketing to you.
      </p>
      <br />
      <p>5. International transfers of personal information</p>
      <br />
      <p>
        The personal information we collect is stored and processed in United
        States, or where we or our partners, affiliates and third-party
        providers maintain facilities. By providing us with your personal
        information, you consent to the disclosure to these overseas third
        parties.
      </p>
      <br />
      <p>
        We will ensure that any transfer of personal information from countries
        in the European Economic Area (EEA) to countries outside the EEA will be
        protected by appropriate safeguards, for example by using standard data
        protection clauses approved by the European Commission, or the use of
        binding corporate rules or other legally accepted means.
      </p>
      <br />
      <p>
        Where we transfer personal information from a non-EEA country to another
        country, you acknowledge that third parties in other jurisdictions may
        not be subject to similar data protection laws to the ones in our
        jurisdiction. There are risks if any such third party engages in any act
        or practice that would contravene the data privacy laws in our
        jurisdiction and this might mean that you will not be able to seek
        redress under our jurisdiction’s privacy laws.
      </p>
      <br />
      <p>6. Your rights and controlling your personal information</p>
      <br />
      <p>Choice and consent:</p>
      <br />
      <p>
        By providing personal information to us, you consent to us collecting,
        holding, using and disclosing your personal information in accordance
        with this privacy policy. If you are under 16 years of age, you must
        have, and warrant to the extent permitted by law to us, that you have
        your parent or legal guardian’s permission to access and use the website
        and they (your parents or guardian) have consented to you providing us
        with your personal information. You do not have to provide personal
        information to us, however, if you do not, it may affect your use of
        this website or the products and/or services offered on or through it.
      </p>
      <br />
      <p>Information from third parties:</p>
      <br />
      <p>
        If we receive personal information about you from a third party, we will
        protect it as set out in this privacy policy. If you are a third party
        providing personal information about somebody else, you represent and
        warrant that you have such person’s consent to provide the personal
        information to us.
      </p>
      <br />
      <p>Restrict:</p>
      <br />
      <p>
        You may choose to restrict the collection or use of your personal
        information. If you have previously agreed to us using your personal
        information for direct marketing purposes, you may change your mind at
        any time by contacting us using the details below. If you ask us to
        restrict or limit how we process your personal information, we will let
        you know how the restriction affects your use of our website or products
        and services.
      </p>
      <br />
      <p>Access and data portability:</p>
      <br />
      <p>
        You may request details of the personal information that we hold about
        you. You may request a copy of the personal information we hold about
        you. Where possible, we will provide this information in CSV format or
        other easily readable machine format. You may request that we erase the
        personal information we hold about you at any time. You may also request
        that we transfer this personal information to another third party.
      </p>
      <br />
      <p>Correction:</p>
      <br />
      <p>
        If you believe that any information we hold about you is inaccurate, out
        of date, incomplete, irrelevant or misleading, please contact us using
        the details below. We will take reasonable steps to correct any
        information found to be inaccurate, incomplete, misleading or out of
        date.
      </p>
      <br />
      <p>Notification of data breaches:</p>
      <br />
      <p>We will comply laws applicable to us in respect of any data breach.</p>
      <br />
      <p>Complaints:</p>
      <p>
        If you believe that we have breached a relevant data protection law and
        wish to make a complaint, please contact us using the details below and
        provide us with full details of the alleged breach. We will promptly
        investigate your complaint and respond to you, in writing, setting out
        the outcome of our investigation and the steps we will take to deal with
        your complaint. You also have the right to contact a regulatory body or
        data protection authority in relation to your complaint.
      </p>
      <br />
      <p>Unsubscribe:</p>
      <br />
      <p>
        To unsubscribe from our e-mail database or opt-out of communications
        (including marketing communications), please contact us using the
        details below or opt-out using the opt-out facilities provided in the
        communication.
      </p>
      <br />
      <p>7. Cookies</p>
      <p>
        We use “cookies” to collect information about you and your activity
        across our site. A cookie is a small piece of data that our website
        stores on your computer, and accesses each time you visit, so we can
        understand how you use our site. This helps us serve you content based
        on preferences you have specified. Please refer to our Cookie Policy for
        more information.
      </p>
      <br />
      <p>8. Business transfers</p>
      <br />
      <p>
        If we or our assets are acquired, or in the unlikely event that we go
        out of business or enter bankruptcy, we would include data among the
        assets transferred to any parties who acquire us. You acknowledge that
        such transfers may occur, and that any parties who acquire us may
        continue to use your personal information according to this policy.
        <br />
      </p>
      <p>9. GDPR Privacy</p>
      <br />
      <p>Legal Basis for Processing Personal Data under GDPR</p>
      <p>We may process Personal Data under the following conditions:</p>
      <br />
      <p>
        Consent: You have given Your consent for processing Personal Data for
        one or more specific purposes.
      </p>
      <br />
      <p>
        Performance of a contract: Provision of Personal Data is necessary for
        the performance of an agreement with You and/or for any pre-contractual
        obligations thereof.
      </p>
      <br />
      <p>
        Legal obligations: Processing Personal Data is necessary for compliance
        with a legal obligation to which the Company is subject.
      </p>
      <br />
      <p>
        Vital interests: Processing Personal Data is necessary in order to
        protect Your vital interests or of another natural person.
      </p>
      <br />
      <p>
        Public interests: Processing Personal Data is related to a task that is
        carried out in the public interest or in the exercise of official
        authority vested in the Company.
      </p>
      <br />
      <p>
        Legitimate interests: Processing Personal Data is necessary for the
        purposes of the legitimate interests pursued by the Company.
      </p>
      <br />
      <p>
        In any case, the Company will gladly help to clarify the specific legal
        basis that applies to the processing, and in particular whether the
        provision of Personal Data is a statutory or contractual requirement, or
        a requirement necessary to enter into a contract.
      </p>
      <br />
      <p>Your Rights under the GDPR</p>
      <br />
      <p>
        The Company undertakes to respect the confidentiality ofYour Personal
        Data and to guarantee You can exercise Your rights.
      </p>
      <br />
      <p>
        You have the right under this Privacy Policy, and by law if You are
        within the EU, to:
      </p>
      <br />
      <p>
        Request access to Your Personal Data. The right to access, update or
        delete the information We have on You. Whenever made possible, you can
        access, update or request deletion of Your Personal Data directly within
        Your account settings section. If you are unable to perform these
        actions yourself, please contact Us to assist You. This also enables You
        to receive a copy of the Personal Data We hold about You.
      </p>
      <br />
      <p>
        Request correction of the Personal Data that We hold about You. You have
        the right to to have any incomplete or inaccurate information We hold
        about You corrected.
      </p>
      <br />
      <p>
        Object to processing of Your Personal Data. This right exists where We
        are relying on a legitimate interest as the legal basis for Our
        processing and there is something about Your particular situation, which
        makes You want to object to our processing of Your Personal Data on this
        ground. You also have the right to object where We are processing Your
        Personal Data for direct marketing purposes.
      </p>
      <br />
      <p>
        Request erasure of Your Personal Data. You have the right to ask Us to
        delete or remove Personal Data when there is no good reason for Us to
        continue processing it.
      </p>
      <br />
      <p>
        Request the transfer of Your Personal Data. We will provide to You, or
        to a third- party You have chosen, Your Personal Data in a structured,
        commonly used, machine-readable format. Please note that this right only
        applies to automated information which You initially provided consent
        for Us to use or where We used the information to perform a contract
        with You.
      </p>
      <br />
      <p>
        Withdraw Your consent. You have the right to withdraw Your consent on
        using your Personal Data. If You withdraw Your consent, We may not be
        able to provide You with access to certain specific functionalities of
        the Service.
      </p>
      <br />
      <p>Exercising of Your GDPR Data Protection Rights</p>
      <br />
      <p>
        You may exercise Your rights of access, rectification, cancellation and
        opposition by contacting Us. Please note that we may ask You to verify
        Your identity before responding to such requests. If You make a request,
        We will try our best to respond to You as soon as possible.
      </p>
      <br />
      <p>
        You have the right to complain to a Data Protection Authority about Our
        collection and use of Your Personal Data. For more information, if You
        are in the European Economic Area (EEA), please contact Your local data
        protection authority in the EEA.
      </p>
      <br />
      <p>10. CCPA Privacy</p>
      <br />
      <p>Your Rights under the CCPA</p>
      <br />
      <p>
        Under this Privacy Policy, and by law if You are a resident of
        California, You have the following rights:
      </p>
      <br />
      <p>
        The right to notice. You must be properly notified which categories of
        Personal Data are being collected and the purposes for which the
        Personal Data is being used.
      </p>
      <br />
      <p>
        The right to access / the right to request. The CCPA permits You to
        request and obtain from the Company information regarding the disclosure
        of Your Personal Data that has been collected in the past 12 months by
        the Company or its subsidiaries to a third-party for the third party's
        direct marketing purposes.
      </p>
      <br />
      <p>
        The right to say no to the sale of Personal Data. You also have the
        right to ask the Company not to sell Your Personal Data to third
        parties. You can submit such a request by visiting our "Do Not Sell My
        Personal Information" section or web page.
      </p>
      <br />
      <p>
        The right to know about Your Personal Data. You have the right to
        request and obtain from the Company information regarding the disclosure
        of the following:
      </p>
      <br />
      <p>- The categories of Personal Data collected.</p>
      <br />
      <p>- The sources from which the Personal Data was collected</p>
      <br />
      <p>
        - The business or commercial purpose for collecting or selling the
        Personal Data
      </p>
      <br />
      <p>- Categories of third parties with whom We share Personal Data</p>
      <br />
      <p>- The specific pieces of Personal Data we collected about You</p>
      <br />
      <p>
        The right to delete Personal Data. You also have the right to request
        the deletion of Your Personal Data that have been collected in the past
        12 months.
      </p>
      <br />
      <p>
        The right not to be discriminated against. You have the right not to be
        discriminated against for exercising any of Your Consumer's rights,
        including by:
      </p>
      <br />
      <p>- Denying goods or services to You</p>
      <br />
      <p>
        - Charging different prices or rates for goods or services, including
        the use of discounts or other benefits or imposing penalties
      </p>
      <br />
      <p>
        - Providing a different level or quality of goods or services to You
      </p>
      <br />
      <p>
        - Suggesting that You will receive a different price or rate for goods
        or services or a different level or quality of goods or services.
      </p>
      <br />
      <p>Exercising Your CCPA Data Protection Rights</p>
      <br />
      <p>
        In order to exercise any of Your rights under the CCPA, and if you are a
        California resident, You can email or call us or visit our "Do Not Sell
        My Personal Information" section or web page.
      </p>
      <br />
      <p>
        The Company will disclose and deliver the required information free of
        charge within 45 days of receiving Your verifiable request.The time
        period to provide the required information may be extended once by an
        additional 45 days when reasonable necessary and with prior notice.
      </p>
      <br />
      <p>11. VCDPA privacy</p>
      <br />
      <p>Information for Virginia consumers</p>
      <br />
      <p>
        This part of the document integrates with and supplements the
        information contained in the rest of the privacy policy and is provided
        by the controller running this Application and, if the case may be, its
        parent, subsidiaries and affiliates (for the purposes of this section
        referred to collectively as “we”, “us”, “our”).
      </p>
      <br />
      <p>
        The provisions contained in this section apply to all Users (Users are
        referred to below, simply as “you”, “your”, “yours”), who are consumers
        residing in the Commonwealth of Virginia, according to the “Virginia
        Consumer Data Protection Act" (the “VCDPA”), and, for such consumers,
        these provisions supersede any other possibly divergent or conflicting
        provisions contained in the privacy policy.
      </p>
      <br />
      <p>
        This part of the document uses the term “personal data” as defined in
        the VCDPA.
      </p>
      <br />
      <p>Categories of personal data processed</p>
      <br />
      <p>
        In this section, we summarize the categories of personal data that we’ve
        processed and the purposes thereof. You can read about these activities
        in detail in the section titled “Detailed information on the processing
        of Personal Data” within this document.
      </p>
      <br />
      <p>Categories of personal data we collect</p>
      <br />
      <p>We have collected the following categories of personal data: E-Mail</p>
      <br />
      <p>
        We will not collect additional categories of personal data without
        notifying you.
      </p>
      <br />
      <p>Why we process your personal data</p>
      <br />
      <p>
        To find out why we process your personal data, you can read the sections
        titled “Detailed information on the processing of Personal Data” and
        “The purposes of processing” within this document.
      </p>
      <br />
      <p>
        We won’t process your information for unexpected purposes, or for
        purposes incompatible with the purposes originally disclosed, without
        your consent.
      </p>
      <br />
      <p>
        You can freely give, deny, or withdraw such consent at any time using
        the contact details provided in this document.
      </p>
      <br />
      <p>
        How use the data we collect: sharing of your personal data with third
        parties
      </p>
      <br />
      <p>We do not share nor disclose your personal data with third parties.</p>
      <br />
      <p>
        For our purposes, the word “third party” means “a natural or legal
        person, public authority, agency, or body other than the consumer,
        controller, processor, or an affiliate of the processor or the
        controller” as defined by the VCDPA.
      </p>
      <br />
      <p>Sale of your personal data</p>
      <br />
      <p>
        We do not sell your personal data. In case we should decide to, we will
        inform you beforehand and will grant your right to opt out of such a
        sale.
      </p>
      <br />
      <p>Processing of your personal data for targeted advertising</p>
      <br />
      <p>
        We do not process your personal data for targeted advertising. If we
        decide to do so, we will inform you beforehand and will grant your right
        to opt out of the processing of your personal data for targeted
        advertising.
      </p>
      <br />
      <p>
        Your privacy rights under the Virginia Consumer Data Protection Act and
        how to exercise them
      </p>
      <br />
      <p>
        You may exercise certain rights regarding your data processed by us. In
        particular, you have the right to do the following:
      </p>
      <br />
      <p>
        access personal data: the right to know. You have the right to request
        that we confirm whether or not we are processing your personal data. You
        also have the right to access such personal data.
      </p>
      <br />
      <p>
        correct inaccurate personal data. You have the right to request that we
        correct any inaccurate personal data we maintain about you, taking into
        account the nature of the personal data and the purposes of the
        processing of the personal data.
      </p>
      <br />
      <p>
        request the deletion of your personal data. You have the right to
        request that we delete any of your personal data.
      </p>
      <br />
      <p>
        obtain a copy of your personal data. We will provide your personal data
        in a portable and usable format that allows you to transfer data easily
        to another entity – provided that this is technically feasible.
      </p>
      <br />
      <p>
        opt out of the processing of your personal data for the purposes of
        targeted advertising, the sale of personal data, or profiling in
        furtherance of decisions that produce legal or similarly significant
        effects concerning you.
      </p>
      <br />
      <p>
        non-discrimination. We will not discriminate against you for exercising
        your rights under the VCDPA. This means that we will not, among other
        things, deny goods or services, charge you a different price, or provide
        a different level or quality of goods or services just because you
        exercised your consumer privacy rights. However, if you refuse to
        provide your personal data to us or ask us to delete or stop selling
        your personal data, and that personal data or sale is necessary for us
        to provide you with goods or services, we may not be able to complete
        that transaction. To the extent permitted by the law, we may offer a
        different price, rate, level, quality, or selection of goods or services
        to you, including offering goods or services for no fee, if you have
        exercised your right to opt out, or our offer is related to your
        voluntary participation in a bona fide loyalty, rewards, premium
        features, discounts, or club card program.
      </p>
      <br />
      <p>How to exercise your rights</p>
      <br />
      <p>
        To exercise the rights described above, you need to submit your request
        to us by contacting us via the contact details provided in this
        document.
      </p>
      <br />
      <p>For us to respond to your request, we need to know who you are.</p>
      <br />
      <p>
        We will not respond to any request if we are unable to verify your
        identity using commercially reasonable efforts and therefore confirm
        that the personal data in our possession actually relates to you. In
        such cases, we may request that you provide additional information which
        is reasonably necessary to authenticate you and your request.
      </p>
      <br />
      <p>
        Making a consumer request does not require you to create an account with
        us. However, we may require you to use your existing account. We will
        use any personal data collected from you in connection with your request
        solely for the purposes of authentication, without further disclosing
        the personal data, retaining it longer than necessary for purposes of
        authentication, or using it for unrelated purposes.
      </p>
      <br />
      <p>
        If you are an adult, you can make a request on behalf of a minor under
        your parental authority.
      </p>
      <br />
      <p>How and when we are expected to handle your request</p>
      <br />
      <p>
        We will respond to your request without undue delay, but in all cases
        and at the latest within 45 days of its receipt. Should we need more
        time, we will explain to you the reasons why, and how much more time we
        need. In this regard, please note that we may take up to 90 days to
        fulfill your request.
      </p>
      <br />
      <p>
        Should we deny your request, we will explain to you the reasons behind
        our denial without undue delay, but in all cases and at the latest
        within 45 days of receipt of the request. It is your right to appeal
        such decision by submitting a request to us via the details provided in
        this document. Within 60 days of receipt of the appeal, we will inform
        you in writing of any action taken or not taken in response to the
        appeal, including a written explanation of the reasons for the
        decisions. If the appeal is denied you may contact the Attorney General
        to submit a complaint.
      </p>
      <br />
      <p>
        We do not charge a fee to respond to your request, for up to two
        requests per year. If your request is manifestly unfounded, excessive or
        repetitive, we may charge a reasonable fee or refuse to act on the
        request. In either case, we will communicate our choices and explain the
        reasons behind them.
      </p>
      <br />
      <p>Do Not Sell My Personal Information</p>
      <br />
      <p>
        We do not sell personal information. However, the Service Providers we
        partner with (for example, our advertising partners) may use technology
        on the Service that "sells" personal information as defined by the CCPA
        law.
      </p>
      <br />
      <p>
        If you wish to opt out of the use of your personal information for
        interest-based advertising purposes and these potential sales as defined
        under CCPA law, you may do so by following the instructions below.
      </p>
      <br />
      <p>
        Please note that any opt out is specific to the browser You use. You may
        need to opt out on every browser that you use.
      </p>
      <br />
      <p>Website</p>
      <br />
      <p>
        You can opt out of receiving ads that are personalized as served by our
        Service Providers by following our instructions presented on the
        Service:
      </p>
      <br />
      <p>From Our "Cookie Consent" notice banner</p>
      <br />
      <p>Or from Our "CCPA Opt-out" notice banner</p>
      <br />
      <p>Or from Our "Do Not Sell My Personal Information" notice banner</p>
      <br />
      <p>Or from Our "Do Not Sell My Personal Information" link</p>
      <br />
      <p>
        The opt out will place a cookie on Your computer that is unique to the
        browser You use to opt out. If you change browsers or delete the cookies
        saved by your browser, you will need to opt out again.
      </p>
      <br />
      <p>Mobile Devices</p>
      <br />
      <p>
        Your mobile device may give you the ability to opt out of the use of
        information about the apps you use in order to serve you ads that are
        targeted to your interests:
      </p>
      <br />
      <p>
        "Opt out of Interest-Based Ads" or"Opt out of Ads Personalization" on
        Android devices
      </p>
      <br />
      <p>"Limit Ad Tracking" on iOS devices</p>
      <br />
      <p>
        You can also stop the collection of location information from Your
        mobile device by changing the preferences on your mobile device.
      </p>
      <br />
      <p>
        "Do Not Track" Policy as Required by California Online Privacy
        Protection Act (CalOPPA)
      </p>
      <br />
      <p>Our Service does not respond to Do Not Track signals.</p>
      <br />
      <p>
        However, some third party websites do keep track of Your browsing
        activities. If You are visiting such websites, You can set Your
        preferences in Your web browser to inform websites that You do not want
        to be tracked. You can enable or disable DNT by visiting the preferences
        or settings page of Your web browser.
      </p>
      <br />
      <p>Your California Privacy Rights (California's Shine the Light law)</p>
      <br />
      <p>
        Under California Civil Code Section 1798 (California's Shine the Light
        law), California residents with an established business relationship
        with us can request information once a year about sharing their Personal
        Data with third parties for the third parties' direct marketing
        purposes.
      </p>
      <br />
      <p>
        If you'd like to request more information under the California Shine the
        Light law, and if you are a California resident, You can contact Us
        using the contact information provided below.
      </p>
      <br />
      <p>
        California Privacy Rights for Minor Users (California Business and
        Professions Code Section 22581)
      </p>
      <br />
      <p>
        California Business and Professions Code section 22581 allow California
        residents under the age of 18 who are registered users of online sites,
        services or applications to request and obtain removal of content or
        information they have publicly posted.
      </p>
      <br />
      <p>
        To request removal of such data, and if you are a California resident,
        You can contact Us using the contact information provided below, and
        include the email address associated with Your account.
      </p>
      <br />
      <p>
        Be aware that Your request does not guarantee complete or comprehensive
        removal of content or information posted online and that the law may not
        permit or require removal in certain circumstances.
      </p>
      <br />
      <p>12. Limits of our policy</p>
      <br />
      <p>
        Our website may link to external sites that are not operated by us.
        Please be aware that we have no control over the content and policies of
        those sites, and cannot accept responsibility or liability for their
        respective privacy practices.
      </p>
      <br />
      <p>13. Changes to this policy</p>
      <br />
      <p>
        At our discretion, we may change our privacy policy to reflect current
        acceptable practices. We will take reasonable steps to let users know
        about changes via our website. Your continued use of this site after any
        changes to this policy will be regarded as acceptance of our practices
        around privacy and personal information.
      </p>
      <br />
      <p>
        If we make a significant change to this privacy policy, for example
        changing a lawful basis on which we process your personal information,
        we will ask you to re-consent to the amended privacy policy.
      </p>
      <br />
      <p>Data Protection Officer</p>
      <br />
      <p>Felix Hamrin</p>
      <br />
      <p>felixhamrin@baitiangong.cn</p>
      <br />
      <p>This policy is effective as of May 6, 2022.</p>
    </div>
  </BasePage>
</template>

<script setup>
import BasePage from "@/components/BasePage/index.vue";
import { reactive } from "vue";
const data = reactive({});
</script>

<style scoped lang="less">
@import "./index.less";
</style>